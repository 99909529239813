export const nycourses = [
    {
        name: "6-Hour New York Point & Insurance Reduction Course",
        link: "https://vih.asicourse.com/checkout?course=ej2yW"
    },
    {
        name: "6-Hour New York Point Insurance Reduction Course (En Español)",
        link: "https://vih.asicourse.com/checkout?course=bk5zx"
    },
]

export const njcourses = [
    {
        name: "6- Hour New Jersey Defensive Driving Course",
        link: "https://vih.asicourse.com/checkout?course=elOAl"
    },
    {
        name: "6-Hour New Jersey Defensive Driving Course (En Español)",
        link: "https://vih.asicourse.com/checkout?course=bmwBp"
    }
]

export const flcourses = [
    {
        name: "12-Hour Florida Advanced Driver Improvement Course",
        link: "https://vih.asicourse.com/checkout?course=aAnBz"
    },
    {
        name: "12-Hour Florida Advanced Driver Improvement Course (En Español)",
        link: "https://vih.asicourse.com/checkout?course=dBgDJ"
    },
    {
        name: "8-Hour Florida Basic Driver Improvement Course",
        link: "https://vih.asicourse.com/checkout?course=axGYr"
    },
    {
        name: "8-Hour Florida Basic Driver Improvement Course (En Español)",
        link: "https://vih.asicourse.com/checkout?course=dy8ZW"   
    },
    {
        name: "6-Hour Florida Mature Driver Course",
        link: "https://vih.asicourse.com/checkout?course=bDRGn"
    },
    {
        name: "6-Hour Florida Mature Driver Course (En Espanol)",
        link: "https://vih.asicourse.com/checkout?course=eElJk"
    },
    {
        name: "4-Hour Florida First Time Driver Course",
        link: "https://vih.asicourse.com/checkout?course=bmwK0"
    },
    {
        name: "4-Hour Florida First Time Driver Course (En Español)",
        link: "https://vih.asicourse.com/checkout?course=enZLl"
    }
]