
import React from 'react';
import Navigation from './navigation/navigation';
import SEOHead from './seo/GlobalHead';
import "./layout.scss";

export default function Layout({children}) {
    return (
        <>
        <SEOHead title="Defensive Driving"/>
        <Navigation showLogo={true}/>
        <div className="wrapper">
            <div className="container main-container">
                {children}
            </div>
        </div>
        </>
    )
}