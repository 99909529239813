import React from 'react';
import "./styles.scss";

export default function CourseListing({name, classes}) {
    return(
        <div className="course-listing">
            <h1> {name} </h1>
            <ul>
                {
                    classes.map((clazz) => {
                        return <li><a href={clazz.link} target="_window"> {clazz.name} </a></li>
                    })
                }
            </ul>
        </div>
    )
}