import React from 'react';
import Layout from '../../../components/Layout';
import CourseListing from '../../../components/defensivedriving/courselisting/';
import { flcourses, njcourses, nycourses } from '../../../enums/defensivedriving/courses';
import icon from "./images/icon.webp"
import "./styles.scss";

export default function DefensiveDrivingView() {
    return (
        <Layout>
            <div className="header-container">
                <img src={icon} alt="Sign Up for Defensive Driving"/>
                <h1> Defensive Driving Courses </h1>
                <p> Take a defensive driving course to learn better safety skills for the road, and to reduce your insurance premiums.</p>
            </div>
            <div className="container">
                <CourseListing name="New York" classes={nycourses}/>
                <CourseListing name="New Jersey" classes={njcourses}/>
                <CourseListing name="Florida" classes={flcourses}/>
            </div>
            <div className="container footer-container">
                <h1> Have questions ? </h1>
                <h1> Call <a href="tel:3476418512"> 347-641-8512</a></h1>
            </div>
        </Layout>
    )
}